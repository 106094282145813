import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useSigninCheck } from 'reactfire';

import Image from 'next/image';
import { Box, Button, Grid, Link, NoSsr, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { t, Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet-async';
import Cookies from 'universal-cookie';
import { i18n } from '@lingui/core';
import { useRouter } from 'next/router';
import { config } from 'react-spring';

import { v4 as uuidv4 } from 'uuid';

const Carousel = dynamic(() => import('react-spring-3d-carousel'), {
	ssr: false
});

// Components
import Footer from './footer';
import { AutoGraph, Balance, Lock, Share, SmartToy } from '@mui/icons-material';
import dynamic from 'next/dynamic';
import { Parallax } from 'react-scroll-parallax';

const Onboarding: FC = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const router = useRouter();

	const { status, data } = useSigninCheck();

	const connected = status === 'success' ? data.signedIn : false;

	const cookies = new Cookies();
	cookies.get('preferred-language');
	const lge = cookies.get('preferred-language') || 'fr';

	if (lge && lge !== i18n.locale) i18n.activate(lge);

	const slides = [
		{
			key: uuidv4(),
			content: (
				<Stack alignItems="center">
					<Image
						alt={'Spécialiste Google Analytics FreewayTeam'}
						src={'/static/images/homepage/team/yuri-avatar.png'}
						width={280}
						height={380}
						style={{ borderRadius: '12px' }}
					/>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 600
						}}
					>
						Yuri Pires Gonçalves
					</Typography>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 400
						}}
					>
						<Trans>Spécialiste Google Analytics </Trans>{' '}
					</Typography>
				</Stack>
			)
		},
		{
			key: uuidv4(),
			content: (
				<Stack alignItems="center">
					<Image
						alt={'Développeuse Web et mobile - FreewayTeam'}
						src={'/static/images/homepage/team/prune-avatar.png'}
						width={280}
						height={380}
						style={{ borderRadius: '12px' }}
					/>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 600
						}}
					>
						Prune Blanc
					</Typography>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 400
						}}
					>
						<Trans>Développeuse Web/Mobile </Trans>
					</Typography>
				</Stack>
			)
		},
		{
			key: uuidv4(),
			content: (
				<Stack alignItems="center">
					<Image
						alt={'Développeuse Web - FreewayTeam'}
						src={'/static/images/homepage/team/carolina-avatar.png'}
						width={280}
						height={380}
						style={{ borderRadius: '12px' }}
					/>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 600
						}}
					>
						Carolina Leon
					</Typography>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 400
						}}
					>
						<Trans>Développeuse Web </Trans>
					</Typography>
				</Stack>
			)
		},
		{
			key: uuidv4(),
			content: (
				<Stack alignItems="center">
					<Image
						alt={'Ingénieur en développement back end - FreewayTeam'}
						src={'/static/images/homepage/team/dam-avatar.png'}
						width={280}
						height={380}
						style={{ borderRadius: '12px' }}
					/>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 600
						}}
					>
						Damiano Deplano
					</Typography>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 400
						}}
					>
						<Trans>Ingénieur en développement back end </Trans>
					</Typography>
				</Stack>
			)
		},
		{
			key: uuidv4(),
			content: (
				<Stack alignItems="center">
					<Image
						alt={'Ingénieur de développement logiciel Full Stack - freewayteam'}
						src={'/static/images/homepage/team/dave-avatar.png'}
						width={280}
						height={380}
						style={{ borderRadius: '12px' }}
					/>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 600
						}}
					>
						David Charlier
					</Typography>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							textAlign: 'center',
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 400
						}}
					>
						<Trans>Ingénieur de développement logiciel Full Stack</Trans>
					</Typography>
				</Stack>
			)
		}
	];

	const [offsetRadius, setOffsetRadius] = useState(200);
	const [showArrows, setShowArrows] = useState(false);
	const [goToSlide, setGoToSlide] = useState(null);

	const table = slides.map((element, index) => {
		return { ...element, onClick: () => setGoToSlide(index) };
	});

	return (
		<Box sx={{ position: 'relative' }}>
			{/*Container*/}
			<Helmet>
				<title>FreewayTeam &bull; {t` Reprenez le contrôle de votre environnement digital`}</title>
				<meta
					name="description"
					content={t`L’app complète de tous vos indicateurs de performance : vente, marketing, cybersécurité, IA et conformité réglementaire. Recevez des alertes instantanées et bénéficiez de l'accompagnement de nos experts à chaque étape pour maximiser votre réussite business.`}
				/>

				<link rel="canonical" href="https://www.freewayteam.com" />
			</Helmet>

			<Box
				id="landingpagetop"
				sx={{
					alignItems: 'center',
					display: 'flex',
					px: '2vw',
					py: 'calc(28px)',
					minHeight: '100vh !important'
				}}
			>
				<Grid
					container
					spacing={2}
					sx={{
						height: '100vh',
						px: isMd ? '8vw' : '4vw',

						backgroundColor: '#727BFE',
						justifyContent: 'left',
						alignItems: 'center',
						padding: isMobile ? '0 20px' : 'none',
						mt: '5vh'
					}}
				>
					{/* Partie Texte à Gauche */}
					<Grid item xs={12} md={6} lg={5} xl={5}>
						<Typography
							variant="h1"
							sx={{
								mt: isMobile ? 4 : 6,

								color: '#FFFFFF',
								width: '105%',

								fontFamily: 'Exo, sans-serif',
								fontSize: isMobile ? '40px !important' : '56px !important',
								textAlign: isMobile ? 'center' : 'left',
								fontStyle: 'normal',
								fontWeight: 700,
								letterSpacing: 'normal',
								lineHeight: 25 / 21
							}}
						>
							<Trans>Reprenez le contrôle de votre environnement digital</Trans>
						</Typography>

						{status === 'loading' ? (
							''
						) : (
							<Button
								size={isMobile ? 'large' : 'large'}
								color="inherit"
								variant="contained"
								sx={{
									width: isMobile ? '95%' : '40%',

									mt: 4,
									mb: 4
								}}
								onClick={() => {
									connected ? router.push('/city') : router.push('/contact');
								}}
							>
								{connected ? t`ALLER SUR MA VILLE` : t`C'EST PARTI !`}
							</Button>
						)}
					</Grid>

					{/* Partie Image à Droite : Image - écrans mobile & desktop*/}
					<Grid item xs={12} md={6} lg={7} xl={7}>
						<Box>
							<Image
								unoptimized
								width={1000}
								height={600}
								src="/static/images/homepage/desktop&mobileMockup.png"
								alt="desktop&mobile"
								style={{
									objectFit: 'cover', // S'assure que l'image couvre toute la zone
									overflow: 'hidden' // Masque la partie dépassant de la zone
								}}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
			{/* 
    ils nous font confiance */}

			{isMobile ? (
				''
			) : (
				<Grid
					item
					lg={12}
					md={12}
					xl={12}
					sx={{
						backgroundColor: '#212529',
						display: 'flex',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
						height: '208px',
						position: 'relative'
					}}
				>
					<Typography
						sx={{
							width: isMd ? '60%' : '50%',
							fontFamily: 'Inter, sans-serif',
							fontSize: 40,
							textAlign: 'center',
							fontStyle: 'normal',
							fontWeight: 600,
							letterSpacing: 'normal',
							lineHeight: 25 / 21,
							my: 4,
							color: '#FFFFFF'
						}}
					>
						<Trans> Ils nous font confiance</Trans>
					</Typography>
					<Stack flexDirection="row" justifyContent={'space-between'} sx={{ width: isMd ? '90%' : '60%', mb: 10 }}>
						<Image
							alt="chamberlan"
							height={40}
							width={160}
							src={'/static/images/homepage/clients/chamberlan_white.png'}
						/>

						<Image alt="fixit" height={40} width={109} src={'/static/images/homepage/clients/fixit_white.png'} />

						<Image
							alt="freemiumplay"
							height={40}
							width={163}
							src={'/static/images/homepage/clients/freemium_white.png'}
						/>

						<Image alt="forecast" height={40} width={95} src={'/static/images/homepage/clients/forecast_white.png'} />
					</Stack>
				</Grid>
			)}
			<Box sx={{ position: 'sticky', top: 0, zIndex: 1, height: 'max-content' }}>
				<Box
					sx={{
						backgroundImage: `url("/static/images/homepage/roadWhiteBackground.svg")`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						backgroundPosition: 'center'
					}}
				>
					<Grid container={true} spacing={0}>
						<Grid
							item={true}
							lg={12}
							md={12}
							xl={12}
							xs={12}
							sx={{
								flexDirection: 'column',
								display: 'flex',
								alignItems: 'center',
								height: isMobile ? '110vh' : isMd ? 'max-content' : '140vh',
								backgroundColor: (theme) => theme.palette.background.paper
							}}
						>
							<Typography
								variant="h2"
								sx={{
									mt: isMobile ? 6 : 4,
									mb: 4,
									color: '#212121',
									width: isMobile ? '100%' : isMd ? '80%' : '45%',

									fontFamily: 'Exo, sans-serif',
									fontSize: isMobile ? '40px !important' : '52px !important',
									textAlign: 'center',
									fontStyle: 'normal',
									fontWeight: 700,
									letterSpacing: '2%',
									lineHeight: 25 / 21
								}}
							>
								<Trans>Accédez au plein potentiel de votre présence en ligne</Trans>
							</Typography>
							<Typography
								sx={{
									color: '#212121',
									width: isMobile ? '90%' : isMd ? '80%' : '60%',

									fontFamily: 'Inter, sans-serif',
									fontSize: 20,
									textAlign: 'center',
									fontStyle: 'normal',
									fontWeight: 500,
									letterSpacing: 'normal',
									lineHeight: 25 / 21
								}}
							>
								<Trans>
									Téléchargez la première application Software With A Service au monde : elle analyse, centralise votre
									environnement digital et en même temps vous match avec les meilleures compétences pour accélérer votre
									croissance.
								</Trans>
							</Typography>
							{isMobile || isMd ? (
								<Image
									alt="App mobile FreewayTeam"
									height={isMobile ? 310 : 620}
									width={isMobile ? 390 : 720}
									src={'/static/images/homepage/mobileMockup.png'}
								/>
							) : (
								<>
									<Image
										alt="App mobile FreewayTeam"
										height={600}
										width={750}
										src={'/static/images/homepage/mobileMockup.png'}
									/>

									<Parallax translateY={[-70, -25]}>
										<Image
											alt="App mobile FreewayTeam"
											height={600}
											width={1000}
											src={'/static/images/homepage/fourItems.svg'}
										/>
									</Parallax>
								</>
							)}
						</Grid>
					</Grid>
				</Box>
			</Box>

			<Box sx={{ position: 'relative', zIndex: 2, backgroundColor: (theme) => theme.palette.background.default }}>
				<Box
					sx={{
						backgroundImage: isMobile
							? `url("/static/images/homepage/radiantMobile.svg")`
							: `url("/static/images/homepage/radiant.svg")`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'start',
						zIndex: 2
					}}
				>
					<Grid container={true} spacing={2}>
						<Grid
							item={true}
							lg={12}
							md={12}
							xl={12}
							xs={12}
							sx={{
								height: 'max-content',
								display: 'flex',
								justifyContent: 'space-around',
								flexDirection: 'column',
								alignItems: 'center',
								backgroundColor: '#212529'
							}}
						>
							<Typography
								variant="h2"
								sx={{
									color: '#FFFFFF',
									width: isMobile ? '100%' : '45%',
									fontFamily: 'Exo, sans-serif',
									fontSize: isMobile ? '88px !important' : '136px !important',
									textAlign: 'center',
									fontStyle: 'normal',
									fontWeight: 700,
									letterSpacing: 'normal',
									lineHeight: 25 / 21,
									opacity: 0.1,
									mt: isMobile ? 2 : 3,
									mb: isMobile ? 0 : 6
								}}
							>
								<Parallax translateY={isMobile ? [0, 0] : isMd ? [40, -15] : [50, -20]}>
									<Trans>FIVE LANES ROAD</Trans>
								</Parallax>
							</Typography>

							<Parallax scale={isMobile ? [1, 0.9] : isMd ? [0.8, 0.9] : [0.8, 1]}>
								<Stack flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-around'}>
									<Grid
										item={true}
										lg={2}
										md={2}
										xl={2}
										xs={12}
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: isMobile ? 'center' : 'flex-start'
										}}
									>
										<Stack
											flexDirection={'row'}
											sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										>
											<Box
												component={'div'}
												sx={{
													height: '100px',
													width: '100px',
													borderRadius: '25px',
													background: '#3DFFCB'
												}}
											/>

											<AutoGraph
												sx={{
													width: '60px',
													height: '60px',
													justifyContent: 'center',
													position: 'absolute',
													color: '#212529'
												}}
											/>
										</Stack>

										<Parallax opacity={[-2, 3]}>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 20,
													textAlign: isMobile ? 'center' : 'none',
													fontStyle: 'normal',
													fontWeight: 600,
													letterSpacing: 'normal',
													lineHeight: 25 / 21,
													mt: 2
												}}
											>
												<Trans>Sales</Trans>
											</Typography>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													textAlign: isMobile ? 'center' : 'none',
													fontSize: 16,
													fontStyle: 'normal',
													fontWeight: 400,
													letterSpacing: 'normal',
													lineHeight: 25 / 21,
													mt: 1,
													mb: isMobile ? 5 : 0,
													pr: 1
												}}
											>
												<Trans>
													Suivez vos métriques business : suivi de la performance, autorité de domaine, etc. La
													meilleure vision macro pour prendre les bonnes décisions.
												</Trans>
											</Typography>
										</Parallax>
									</Grid>
									<Grid
										item={true}
										lg={2}
										md={2}
										xl={2}
										xs={12}
										sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'flex-start' }}
									>
										<Stack
											flexDirection={'row'}
											sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										>
											<Box
												component={'div'}
												sx={{
													height: '100px',
													width: '100px',
													borderRadius: '25px',
													background: '#E2FF07'
												}}
											/>
											<Share
												sx={{
													width: '60px',
													height: '60px',
													justifyContent: 'center',
													position: 'absolute',
													color: '#212529'
												}}
											/>
										</Stack>
										<Parallax opacity={[-2, 3]}>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 20,
													fontStyle: 'normal',
													fontWeight: 600,
													letterSpacing: 'normal',
													textAlign: isMobile ? 'center' : 'none',
													lineHeight: 25 / 21,
													mt: 2
												}}
											>
												<Trans> Marketing</Trans>
											</Typography>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 16,
													fontStyle: 'normal',
													fontWeight: 400,
													letterSpacing: 'normal',
													textAlign: isMobile ? 'center' : 'none',
													lineHeight: 25 / 21,
													mt: 1,
													mb: isMobile ? 5 : 0,
													pr: 1
												}}
											>
												<Trans>
													Soyez proactif dans la gestion de votre réputation en ligne, l'exécution de campagnes
													marketing efficaces. Générez du trafic et rayonnez en ligne.
												</Trans>
											</Typography>
										</Parallax>
									</Grid>
									<Grid
										item={true}
										lg={2}
										md={2}
										xl={2}
										xs={12}
										sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'flex-start' }}
									>
										<Stack
											flexDirection={'row'}
											sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										>
											<Box
												component={'div'}
												sx={{
													height: '100px',
													width: '100px',
													borderRadius: '25px',
													background: '#FF9431'
												}}
											/>
											<Lock
												sx={{
													width: '60px',
													height: '60px',
													justifyContent: 'center',
													position: 'absolute',
													color: '#212529'
												}}
											/>
										</Stack>
										<Parallax opacity={[-2, 3]}>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 18,
													fontStyle: 'normal',
													fontWeight: 600,
													letterSpacing: 'normal',
													textAlign: isMobile ? 'center' : 'none',
													lineHeight: 25 / 21,
													mt: 2
												}}
											>
												<Trans>Cybersécurité</Trans>
											</Typography>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 16,
													fontStyle: 'normal',
													fontWeight: 400,
													letterSpacing: 'normal',
													textAlign: isMobile ? 'center' : 'none',
													lineHeight: 25 / 21,
													mt: 1,
													mb: isMobile ? 5 : 0,
													pr: 1
												}}
											>
												<Trans>
													Analysez parfaitement votre environnement pour une protection optimale de vos données :
													brèches de sécurité, hack… préservez votre sérénité.
												</Trans>
											</Typography>
										</Parallax>
									</Grid>
									<Grid
										item={true}
										lg={2}
										md={2}
										xl={2}
										xs={12}
										sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'flex-start' }}
									>
										<Stack
											flexDirection={'row'}
											sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										>
											<Box
												component={'div'}
												sx={{
													height: '100px',
													width: '100px',
													borderRadius: '25px',
													background: '#FF645C'
												}}
											/>
											<SmartToy
												sx={{
													width: '60px',
													height: '60px',
													justifyContent: 'center',
													position: 'absolute',
													color: '#212529'
												}}
											/>
										</Stack>
										<Parallax opacity={[-2, 3]}>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 18,
													fontStyle: 'normal',
													fontWeight: 600,
													letterSpacing: 'normal',
													textAlign: isMobile ? 'center' : 'none',
													lineHeight: 25 / 21,
													mt: 2
												}}
											>
												<Trans>IA</Trans>
											</Typography>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 16,
													fontStyle: 'normal',
													fontWeight: 400,
													letterSpacing: 'normal',
													lineHeight: 25 / 21,
													textAlign: isMobile ? 'center' : 'none',
													mt: 1,
													mb: isMobile ? 5 : 0,
													pr: 1
												}}
											>
												<Trans>Retrouvez le meilleur de l’IA pour accélérer votre business</Trans>
											</Typography>
										</Parallax>
									</Grid>
									<Grid
										item={true}
										lg={2}
										md={2}
										xl={2}
										xs={12}
										sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'flex-start' }}
									>
										<Stack
											flexDirection={'row'}
											sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
										>
											<Box
												component={'div'}
												sx={{
													height: '100px',
													width: '100px',
													borderRadius: '25px',
													background: '#B377FE'
												}}
											/>
											<Balance
												sx={{
													width: '60px',
													height: '60px',
													justifyContent: 'center',
													position: 'absolute',
													color: '#212529'
												}}
											/>
										</Stack>
										<Parallax opacity={[-2, 3]}>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 18,
													fontStyle: 'normal',
													fontWeight: 600,
													letterSpacing: 'normal',
													lineHeight: 25 / 21,
													textAlign: isMobile ? 'center' : 'none',
													mt: 2
												}}
											>
												<Trans>Réglementaire</Trans>
											</Typography>
											<Typography
												sx={{
													color: '#FFFFFF',
													fontFamily: 'Inter, sans-serif',
													fontSize: 16,
													fontStyle: 'normal',
													fontWeight: 400,
													letterSpacing: 'normal',
													textAlign: isMobile ? 'center' : 'none',
													lineHeight: 25 / 21,
													mt: 1,
													mb: isMobile ? 5 : 0,
													pr: 1
												}}
											>
												<Trans>
													Soyez informés des obligations réglementaires liées à votre espace digital : RGPD, sécurité…
													tout en un clic.
												</Trans>
											</Typography>
										</Parallax>
									</Grid>
								</Stack>
							</Parallax>

							<Typography
								variant="h3"
								sx={{
									width: isMobile ? '68%' : isMd ? '70%' : '37%',
									fontFamily: 'Inter, sans-serif',
									fontSize: isMobile ? '24px !important' : '28px !important',
									textAlign: 'center',
									fontStyle: 'normal',
									fontWeight: 600,
									letterSpacing: 'normal',
									lineHeight: 25 / 21,
									my: 5,
									mt: isMobile ? 0 : 10,
									color: '#FFF'
								}}
							>
								<Trans>FreewayTeam vous accompagne sur les 5 axes principaux de votre business</Trans>
							</Typography>
							<Button
								onClick={() => {
									router.push('/contact');
								}}
								sx={{ mb: isMobile ? 10 : 12 }}
								color="primary"
								size={'large'}
								variant="contained"
							>
								{t`NOUS CONTACTER`}
							</Button>
						</Grid>
					</Grid>
				</Box>

				<Grid container={true} spacing={3}>
					<Grid
						item={true}
						lg={12}
						md={12}
						xl={12}
						xs={12}
						sx={{
							backgroundColor: (theme) => theme.palette.background.paper
						}}
					>
						<Box
							sx={{
								height: 'auto',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',

								backgroundPosition: 'center'
							}}
						>
							<Typography
								variant="h2"
								sx={{
									color: '#212121',
									width: isMobile ? '90%' : isMd ? '100%' : 'auto',
									fontFamily: 'Exo, sans-serif',
									fontSize: isMobile ? '40px !important' : '52px !important',
									textAlign: 'center',
									fontStyle: 'normal',
									fontWeight: 700,
									letterSpacing: 'normal',
									lineHeight: 25 / 21,
									mb: 4,
									wordSpacing: '2%'
								}}
							>
								<Trans>La tech au service de l’humain</Trans>
							</Typography>

							<Typography
								sx={{
									color: '#212121',
									width: isMobile || isMd ? '90%' : '952px',
									fontFamily: 'Inter, sans-serif',
									fontSize: 20,
									textAlign: 'center',
									fontStyle: 'normal',
									fontWeight: 500,
									letterSpacing: 'normal',
									lineHeight: 25 / 21,
									mb: 4
								}}
							>
								<Trans>
									Dans FreewayTeam, il y a le mot Team. Car nous mettons l'humain au centre de notre proposition de
									valeur, soyez entouré des meilleurs copilotes de la place.
								</Trans>
							</Typography>
							<Box sx={{ height: '500px', width: isMobile ? '50%' : isMd ? '70%' : '48%', margin: '0 auto' }}>
								<Carousel
									goToSlideDelay={null}
									animationConfig={config.gentle}
									goToSlide={goToSlide}
									offsetRadius={offsetRadius}
									showNavigation={showArrows}
									slides={table}
								/>
							</Box>
							<Button
								sx={{ mb: 10, width: isMobile ? '90%' : 'none' }}
								color="inherit"
								size={'large'}
								variant="contained"
								onClick={() => {
									router.push('/contact');
								}}
							>
								{t`PRENDRE RENDEZ-VOUS AVEC NOS EXPERTS`}
							</Button>
						</Box>
					</Grid>
				</Grid>
				<NoSsr>
					<Footer />
				</NoSsr>
			</Box>
		</Box>
	);
};

export default React.memo(Onboarding);
