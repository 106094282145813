import { FC, ReactNode, useState } from 'react';

// Externals
import Image from 'next/image';
import { Box, Button, Grid, Link, NoSsr, Stack, Switch, Theme, Typography, useMediaQuery } from '@mui/material';
import { t, Trans } from '@lingui/macro';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

// Internals
import LanguageFlag from 'components/legacy/components/layouts/language-flag';

// query
import { useContactSupportMutation } from 'components/legacy/services/contact';
import { useSigninCheck } from 'reactfire';
import { Call } from '@mui/icons-material';

// Services

interface FooterProps {
	children?: ReactNode;
}

const Footer: FC<FooterProps> = ({ children }) => {
	const router = useRouter();

	const { status, data } = useSigninCheck();
	const connected = status === 'success' ? data.signedIn : false;

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

	return (
		<>
			<Box
				id="footer"
				sx={{
					zIndex: 1,
					background: '#212529',
					width: '100%',
					height: 'max-content',
					borderTop: (theme) => `1px solid ${theme.palette.secondary.light}`,
					px: '2vw',
					py: 'calc(28px)'
				}}
			>
				<Box
					sx={{
						mx: 'auto',
						width: xlUp ? '80%' : '100%',
						display: 'flex',
						flexDirection: 'row'
					}}
				>
					<Grid container={true} spacing={3}>
						<Grid
							item={true}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'none' }}
							xs={isMobile ? 12 : 4}
						>
							<Box
								sx={{
									display: {
										xs: 'block',
										md: 'none'
									}
								}}
							>
								<Box
									alt="FreewayTeam"
									component="img"
									id="logo_home"
									src="/static/icons/brand/logo_white.svg"
									sx={{
										height: 'auto',
										width: 200,
										mt: isMobile ? 0 : 2,
										mb: isMobile ? 2 : 0
									}}
								/>
							</Box>
							<Typography
								sx={{
									color: '#FFF',
									fontFamily: 'Inter, sans-serif',
									fontSize: 18,
									fontStyle: 'normal',
									fontWeight: 600,
									letterSpacing: 'normal',
									lineHeight: 25 / 21,
									mb: 2
								}}
							>
								{t`Nous suivre`}
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
								<Box
									sx={{
										cursor: 'pointer',
										marginRight: '16px'
									}}
								>
									<a target="_blank" href="https://www.instagram.com/freewayteam1/">
										<Image
											alt="instagram"
											id="go_to_ig"
											src={'/static/images/homepage/social/instagram.png'}
											width={32}
											height={32}
											style={{ backgroundColor: '#212121' }}
										/>
									</a>
								</Box>
								<Box
									sx={{
										cursor: 'pointer',
										marginRight: '16px'
									}}
								>
									<a target="_blank" href="https://www.facebook.com/FreewayTeamOfficiel/">
										<Image
											alt="facebook"
											id="go_to_fb"
											src={'/static/images/homepage/social/facebook.png'}
											width={32}
											height={32}
											style={{ backgroundColor: '#212121' }}
										/>
									</a>
								</Box>
								<Box
									sx={{
										cursor: 'pointer',
										marginRight: '16px'
									}}
								>
									<a target="_blank" href="https://www.linkedin.com/company/freewayteam">
										<Image
											alt="linkedin"
											id="go_to_linkedin"
											src={'/static/images/homepage/social/linkedin.png'}
											width={32}
											height={32}
											style={{ backgroundColor: '#212121' }}
										/>
									</a>
								</Box>
								<Box
									sx={{
										cursor: 'pointer'
									}}
								>
									<a target="_blank" href="https://twitter.com/freewayteamoff">
										<Image
											alt="twitter"
											id="go_to_twitter"
											src={'/static/images/homepage/social/twitter.png'}
											width={32}
											height={32}
											style={{ backgroundColor: '#212121' }}
										/>
									</a>
								</Box>
							</Box>
							<Stack justifyContent={'start'}>
								<a
									href="https://tree-nation.com/fr/profil/impact/urbiquest#co2"
									rel="noreferrer"
									style={{
										cursor: 'pointer'
									}}
									target="_blank"
								>
									<Image
										width={157}
										height={50}
										unoptimized
										alt="treenation"
										src="https://tree-nation.com/images/tracking/label-co2-website-white-en.png"
									/>
								</a>
								<NoSsr>
									<script async src="https://tree-nation.com/js/track.js"></script>
									<script>treenation_track("63ea1787e8161");</script>
								</NoSsr>
								<Box
									sx={{
										display: {
											md: 'block',
											xs: 'none'
										}
									}}
								>
									<Box
										alt="FreewayTeam"
										component="img"
										id="logo_home"
										src={'/static/icons/brand/logo_white.svg'}
										sx={{
											height: 'auto',
											width: 200,
											mt: 2
										}}
									/>
								</Box>
							</Stack>
						</Grid>

						<Grid
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: isMobile ? 'center' : 'none'
							}}
							item={true}
							xs={isMobile ? 12 : 4}
						>
							<Typography sx={{ display: 'flex', flexDirection: 'column' }} variant="body1">
								<Typography
									sx={{
										color: '#FFF',
										fontFamily: 'Inter, sans-serif',
										fontSize: 18,
										fontStyle: 'normal',
										fontWeight: 600,
										textAlign: 'center',
										letterSpacing: 'normal',
										lineHeight: 25 / 21,
										mb: 2
									}}
								>
									{t`Sur le site`}
								</Typography>
								<Link
									href="/contact"
									sx={{
										color: '#FFF',
										textAlign: 'center',
										fontSize: '14px'
									}}
									underline="hover"
								>
									{t`Contact`}
								</Link>
								<Link
									href="/blog"
									sx={{
										color: '#FFF',
										textAlign: 'center',
										fontSize: '14px'
									}}
									underline="hover"
								>
									{t`Blog`}
								</Link>
								<Link
									href="/privacy"
									sx={{
										color: '#FFF',
										textAlign: 'center',
										fontSize: '14px'
									}}
									underline="hover"
								>
									{t`Confidentialité`}
								</Link>
								<Link
									href="/cgu"
									sx={{
										color: '#FFF',
										textAlign: 'center',
										fontSize: '14px'
									}}
									underline="hover"
								>
									{t`CGU`}
								</Link>
								<Link
									href="/cgs"
									sx={{
										color: '#FFF',
										textAlign: 'center',
										fontSize: '14px'
									}}
									underline="hover"
								>
									{t`CGS`}
								</Link>
								<Link
									href="/google"
									sx={{
										color: '#FFF',
										textAlign: 'center',
										fontSize: '14px'
									}}
									underline="hover"
								>
									{t`Google disclosure`}
								</Link>
							</Typography>
						</Grid>
						<Grid
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: isMobile ? 'center' : 'end',
								fontSize: '14px'
							}}
							item={true}
							xs={isMobile ? 12 : 4}
						>
							<Typography
								sx={{
									color: '#FFF',
									fontFamily: 'Inter, sans-serif',
									fontSize: 18,
									fontStyle: 'normal',
									fontWeight: 600,
									letterSpacing: 'normal',
									lineHeight: 25 / 21
								}}
							>{t`Contactez-nous !`}</Typography>

							<Button
								size={'small'}
								variant="outlined"
								color="inherit"
								href="tel:+33140961286"
								sx={{
									p: '8px !important',
									my: '8px !important',
									borderRadius: '8px !important',
									width: isMobile ? 'auto' : '250px',
									fontSize: '18px',
									color: '#FFF'
								}}
							>
								+331 40 96 12 86
								<Call sx={{ ml: 2 }} />
							</Button>
							<Typography sx={{ color: '#FFF' }} variant="body1" fontSize={'14px'}>
								contact@freewayteam.com
							</Typography>

							<Typography
								sx={{
									color: '#FFF',
									fontFamily: 'Inter, sans-serif',
									fontSize: 18,
									fontStyle: 'normal',
									fontWeight: 600,
									letterSpacing: 'normal',
									lineHeight: 25 / 21,
									mb: 2,
									mt: isMobile ? 2 : 0
								}}
							>{t`Parlez à nos équipes !`}</Typography>
							<Button
								color={'primary'}
								size={isMobile ? 'large' : 'medium'}
								variant="contained"
								sx={{
									width: isMobile ? '95%' : '215px' // Ajustement de la largeur
								}}
								onClick={() => {
									router.push('/contact');
								}}
							>
								{t`DEMANDEZ UNE DEMO`}
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box
				id="copyright"
				sx={{
					zIndex: 1,
					background: '#E5E7FF',
					width: '100%',
					px: '2vw',
					py: 'calc(28px)'
				}}
			>
				<Box
					sx={{
						mx: 'auto',
						width: xlUp ? '80%' : '100%',
						display: 'flex',
						flexDirection: 'row'
					}}
				>
					<Grid container={true} spacing={3}>
						<Grid
							item={true}
							sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : 'none' }}
							xs={isMobile ? 12 : 4}
						>
							<Typography
								sx={{
									color: '#000',
									fontFamily: 'Inter, sans-serif',
									fontSize: 13,
									fontStyle: 'normal',
									fontWeight: 400,
									letterSpacing: 'normal',
									lineHeight: 25 / 21
								}}
							>
								<Trans>FreewayTeam Copyright 2024 - tous droits réservés</Trans>
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default Footer;
